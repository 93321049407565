import React from "react"

const ContactInfo = () => {
  return (
    <div className="contact-information">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="contact-item">
              <i className="fa fa-phone" />
              <h4>Phone</h4>
              <a href="tel:+1 224 269 4410">+1 224 269 4410</a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-item">
              <i className="fa fa-envelope" />
              <h4>Email</h4>
              <a href="mailto:support@eldkit.net">support@eldkit.net</a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-item">
              <i className="fa fa-map-marker" />
              <h4>Location</h4>
              <button>
                ELD KIT office: 1699 Wall st  ste 550Q Mount Prospect . IL 60056
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
import React from "react"
import Layout from "../components/navigation/layout"
import ContactHeading from "../components/contactHeading"
import ContactInfo from "../components/contactInfo"
import ContactForm from "../components/ContactForm"
import Map from "../components/map"
import Seo from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <ContactHeading />
      <ContactInfo />
      <ContactForm background="#f7f7f7" color="transparent" button="#a4c639"/>
      <Map />
    </Layout>
  )
}

export default Contact
import React from "react"

const Map = () => {
  return (
    <div id="map">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2957.489419782712!2d-87.93096878425611!3d42.16121165562786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fbc20fe89fc75%3A0x7a724908677f18c0!2zNzAwIFBpbnRhaWwgQ3QsIERlZXJmaWVsZCwgSUwgNjAwMTUsINCh0KjQkA!5e0!3m2!1sru!2s!4v1633697187657!5m2!1sru!2s"
        style={{width:"100%", height:"500px", frameBorder:"0", border: "0" }}
        allowFullScreen=""
        loading="lazy"
      />
    </div>
  )
}

export default Map
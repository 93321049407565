import React from "react"

const ContactHeading = () => {
  return (
    <div className="page-heading header-text">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Contact Us</h1>
            <span>feel free to send us a message now!</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactHeading